import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const sponsorshipLevels = [
  {
    title: 'Presenting $10,000',
    availability: '1 available',
    bulletPoints: [
      '20 tickets',
      '1 couch, 3 lounge chairs, 3 highboys',
      'On-stage recognition',
      'Logo recognition in event program',
      'Logo placement in event presentation',
      'Logo on digital invitation',
      'Logo and link on LANDMARK webpage and social media',
    ],
  },
  {
    title: 'Platinum $5,000',
    availability: '4 available',
    bulletPoints: [
      '12 tickets',
      '1 couch, 2 highboys',
      'On-stage recognition',
      'Logo recognition in event program',
      'Logo placement in event presentation',
      'Logo on digital invitation',
      'Logo and link on LANDMARK webpage and social media',
    ],
  },
  {
    title: 'Gold $3,000',
    availability: '8 available',
    bulletPoints: [
      '8 tickets',
      '2 lounge chairs, 1 highboy',
      'Logo recognition in event program',
      'Logo placement in event presentation',
      'Logo and link on LANDMARKwebpage and social media',
    ],
  },
  {
    title: 'Silver $1,000',
    availability: null,
    bulletPoints: [
      '4 tickets',
      '1 highboy',
    ],
  }
];

const Sponsorship = () => (
  <Layout>
    <SEO title="Landmark Sponsorship" />
    <p style={{ fontSize: '85%' }}><Link to="/" style={{ color: '#000051', textDecoration: 'none' }}>&#x2190; Back to home</Link></p>
    <h1>Landmark Sponsorship Levels</h1>
    {sponsorshipLevels.map((sponsorshipLevel) => (
      <div key={sponsorshipLevel.title}>
        <h3 style={{ marginBottom: '0.5rem' }}>{sponsorshipLevel.title}</h3>
        <p style={{ marginBottom: '0.5rem' }}><em>{sponsorshipLevel.availability}</em></p>
        <ul>
          {sponsorshipLevel.bulletPoints.map((bulletPointForLevel, index) => (
            <li style={{ marginBottom: '0' }} key={index}>{bulletPointForLevel}</li>
          ))}
        </ul>
      </div>
    ))}
    <p>To download a copy of the sponsorship packet please <a href="https://www.dropbox.com/s/k6imd8kaor4dz2p/Landmark%20Legacy%20Project%20%5BUn%5DGala%20Sponsorship%20Packet%281%29.pdf?dl=0" style={{ color: '#000051' }} target="_blank" rel="noopener noreferrer">click HERE</a>.</p>
    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post" style={{
      background: '#FCE7B0',
      padding: '40px 20px',
    }}>
      <input type="hidden" name="cmd" value="_s-xclick" />
      <input type="hidden" name="hosted_button_id" value="3PSK2QTLQHD78" />
      <input type="hidden" name="on0" value="Select Sponsor Level"/><h2>Select Sponsor Level</h2>
      <select name="os0" style={{ marginBottom: '20px', width: '100%' }}>
        <option value="Presenting Sponsor">Presenting Sponsor $10,000.00 USD</option>
        <option value="Platinum Sponsor">Platinum Sponsor $5,000.00 USD</option>
        <option value="Gold Sponsor">Gold Sponsor $3,000.00 USD</option>
        <option value="Silver Sponsor">Silver Sponsor $1,000.00 USD</option>
      </select>
      <input type="hidden" name="currency_code" value="USD" />
      <button type="submit" border="0" name="submit" style={{
        background: 'linear-gradient(to right, #FFDA00, #FFB300)',
        border: '0',
        color: 'black',
        textDecoration: 'none',
        padding: '10px 30px',
      }}>Add to cart</button>
      <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
    </form>
    <h3>Notice and Consent</h3>
    <p>At the October 10th LandMark: The Legacy Project [Un]Gala, The Legacy Project will take photographs and audio and video recordings, including interviews. The Legacy Project, the staging and AV vendor for the event, Luxe Productions, and the venue, Chez Event Space, intend to use the footage for their own advertising and promotional materials, including use on social media, websites, and through email.</p>
    <p>By attending LandMark: The Legacy Project [Un]Gala, you consent to be photographed, filmed, audio or video recorded, and/or interviewed by the Legacy Project and give permission to each of The Legacy Project, Chez Event Space (Landmark event venue) and Luxe Productions (Landmark staging and AV vendor) to use photographs of the event, including sound or video recordings of you, including your voice, identity, image, likeness, appearance and performance, for purposes of public distribution, advertising and/or promotional materials, including use on social media, websites, and email distribution.</p>
  </Layout>
)

export default Sponsorship
